<script lang="ts">
  import { user } from "$lib/user";
  import FilloutEmbed from "./FilloutEmbed.svelte";
  import MarketingButton from "./MarketingButton.svelte";

  let demoFormOpen = false;
</script>

<MarketingButton
  href={$user?.logged_in ? "/apps" : "/signup"}
  variant="primary"
>
  Get Started
</MarketingButton>
<MarketingButton on:click={() => (demoFormOpen = true)}>
  Book a Demo
</MarketingButton>

<FilloutEmbed
  formId="onUBuQZ5vCus"
  title="Book a demo form"
  open={demoFormOpen}
  on:close={() => (demoFormOpen = false)}
/>
